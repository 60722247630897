<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
        [readOnly]="fieldViewMode == 'view'"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.riskStatement.label' | translate: 'Risk Statement'"
        [placeholder]="moduleFieldString + '.riskStatement.placeholder' | translate: 'Search Risk Statements'"
        [control]="formGroup?.controls?.riskStatement"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['RISK_STATEMENT']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.category.label' | translate: 'Category'"
        [placeholder]="moduleFieldString + '.category.placeholder' | translate: 'Search Categories'"
        [control]="formGroup?.controls?.category"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['RISK_STATEMENT_CATEGORY']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.entityId.label' | translate: 'Entity'"
        [placeholder]="moduleFieldString + '.entityId.placeholder' | translate: 'Search Entity'"
        [control]="formGroup?.controls?.entityId"
        [viewMode]="fieldViewMode"
        [singleTableMode]="true"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="undefined"
        [dataKey]="'code'"
        [targetTypes]="['RESPONSIBILITY', 'ASSET', 'OBJECTIVE', 'PROCESS', 'EVENT', 'GEOGRAPHY', 'ORGANIZATION']"
      >
      </app-target-code-selector>
      <!-- <app-entity-category-tree-selector [label]="'Risk Entity'"
      [categoryList]="categoryList"
      [placeholder]="'Search Entities'"
      [control]="formGroup?.controls?.entityId"
      [multi]="false"
      [selectedEntities]="[data?.entityId]"
      [viewMode]="fieldViewMode"
      [dropDown]="true"
      [returnObject]="true">
    </app-entity-category-tree-selector> -->
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.project.label' | translate: 'Risk Project'"
        [placeholder]="moduleFieldString + '.project.placeholder' | translate: 'Search Risk Projects'"
        [control]="formGroup?.controls?.project"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="undefined"
        [customProjectionFields]="[
          'code',
          'recordStatus',
          'label',
          'name',
          'riskStatement',
          'riskAssessment',
          'description',
        ]"
        [dataKey]="'code'"
        [targetTypes]="['RISK_PROJECT']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.approver.label' | translate: 'Approver'"
        [placeholder]="moduleFieldString + '.approver.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.approver"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>

    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'200px'"
        [name]="'textarea'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
    <div class="w-full">
      <p-divider class="w-full" align="left">
        <b>{{ moduleFieldString + '.riskAssessmentId.label' | translate: 'Risk Assessment' }}</b>
      </p-divider>
    </div>
    @if (!(this.data?.riskAssessment || this.data?.riskAssessmentId)) {
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="moduleFieldString + '.riskAssessmentId.label' | translate: 'Risk Assessment'"
          [placeholder]="moduleFieldString + '.riskAssessmentId.placeholder' | translate: 'Search Risk Assessment'"
          [control]="formGroup?.controls?.riskAssessmentId"
          [viewMode]="fieldViewMode"
          [readOnly]="fieldViewMode != 'view'"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [targetTypes]="['QUESTIONNAIRE']"
        >
        </app-target-code-selector>
      </div>
    }
    @if (this.data?.riskAssessment || this.data?.riskAssessmentId) {
      <div class="col-12 md:col-6">
        <app-assessment-field
          [viewModeOnly]="true"
          [questionnaireCode]="
            this.data?.riskAssessment ||
            this.data?.riskAssessmentId ||
            formGroup?.controls?.riskAssessmentId?.getRawValue()
          "
          [questionnaireValueCode]="this.data?.questionnaireValueCode"
          [label]="moduleFieldString + '.riskStatement.label' | translate: 'Risk Assessment'"
          [control]="answersControl"
        >
        </app-assessment-field>
      </div>
    }
    <p-divider class="w-full" align="left">
      <b>{{ moduleFieldString + '.threatsAndVulnerabilities.label' | translate: 'Threats & Vulnerabilities' }}</b>
    </p-divider>
    <div class="col-12 md:col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.riskThreats.label' | translate: 'Risk Threats'"
        [placeholder]="moduleFieldString + '.riskThreats.placeholder' | translate: 'Search Risk Threats'"
        [control]="formGroup?.controls?.riskThreats"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['RISK_THREAT']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.riskVulnerabilities.label' | translate: 'Risk Vulnerabilities'"
        [placeholder]="
          moduleFieldString + '.riskVulnerabilities.placeholder' | translate: 'Search Risk Vulnerabilities'
        "
        [control]="formGroup?.controls?.riskVulnerabilities"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['RISK_VULNERABILITY']"
      >
      </app-target-code-selector>
    </div>
    <p-divider class="w-full" align="left">
      <b>{{ moduleFieldString + '.Inherited.label' | translate: 'Inherited' }}</b>
    </p-divider>
    <div class="flex flex-column w-12 md:w-6">
      @if (optionsFields?.length > 0) {
        <div class="col-12 mb-4">
          <app-form-repeater
            [title]="''"
            [label]="moduleFieldString + '.factorsRepeaterControl.label' | translate: 'Impact Factors'"
            [viewMode]="impactFactorsViewMode"
            [cols]="optionsFields"
            [control]="formGroup?.controls?.factorsRepeaterControl"
            [addOrderFieldToOptions]="false"
            [showAddForm]="true"
            [showReorder]="false"
            [showAddFormAsPopup]="true"
          ></app-form-repeater>
        </div>
      }
    </div>
    <div class="flex flex-column w-12 md:w-6">
      <div class="col-12">
        <app-drop-down-input
          [label]="moduleFieldString + '.inheritedRiskImpact.label' | translate: 'Inherited Risk Impact'"
          [placeholder]="
            moduleFieldString + '.inheritedRiskImpact.placeholder' | translate: 'Select Inherited Risk Impact'
          "
          [name]="'in-risk-imp'"
          [optionLabel]="'label'"
          [items]="impactList"
          [control]="riskImpactObjectControl"
          [viewMode]="'view'"
          [dataKey]="'code'"
          [showStatusBadge]="false"
        ></app-drop-down-input>
      </div>
      <div class="col-12">
        <app-drop-down-input
          [label]="moduleFieldString + '.inheritedLikelihood.label' | translate: 'Inherited Likelihood'"
          [placeholder]="
            moduleFieldString + '.inheritedLikelihood.placeholder' | translate: 'Select Inherited Likelihood'
          "
          [name]="'in-risk-imp'"
          [optionLabel]="'name'"
          [items]="likelihoodList"
          [control]="formGroup?.controls?.inheritedLikelihoodDto"
          [viewMode]="fieldViewMode"
          [optionValue]="undefined"
          [dataKey]="'code'"
          [showStatusBadge]="false"
        >
          <ng-template let-item #selectedItemTemplate>
            <span class="">
              {{
                item.label +
                  ' ( ' +
                  ('general.actions.from' | translate) +
                  ' ' +
                  item.occuresFrom +
                  ' ' +
                  ('general.actions.to' | translate) +
                  ' ' +
                  item.occuresTo +
                  (item.occurrenceDuration
                    ? ' ' + ('general.actions.times per' | translate) + ' ' + (item.occurrenceDuration | humanize)
                    : '') +
                  ' )'
              }}
            </span>
          </ng-template>
          <ng-template let-item #itemTemplate>
            <span class="">
              {{
                item.label +
                  ' ( ' +
                  ('general.actions.from' | translate) +
                  ' ' +
                  item.occuresFrom +
                  ' ' +
                  ('general.actions.to' | translate) +
                  ' ' +
                  item.occuresTo +
                  (item.occurrenceDuration
                    ? ' ' + ('general.actions.times per' | translate) + ' ' + (item.occurrenceDuration | humanize)
                    : '') +
                  ' )'
              }}
            </span>
          </ng-template>
        </app-drop-down-input>
      </div>
      <div class="col-12">
        <app-risk-meter-bar
          [label]="moduleFieldString + '.inheritedRisk.label' | translate: 'Inherited Risk'"
          [riskMeth]="riskMeth"
          [value]="formGroup?.controls?.inheritedRisk?.value"
          [viewMode]="fieldViewMode"
        ></app-risk-meter-bar>
      </div>
      <div class="col-12">
        <app-drop-down-input
          [label]="moduleFieldString + '.inheritedRiskAcceptance.label' | translate: 'Inherited Risk Acceptance'"
          [placeholder]="moduleFieldString + '.inheritedRiskAcceptance.placeholder' | translate: 'Select Item'"
          [name]="'inheritedRiskAcceptanceList'"
          [optionLabel]="'label'"
          [items]="inheritedRiskAcceptanceList"
          [optionValue]="'value'"
          [control]="formGroup?.controls?.inheritedRiskAcceptance"
          [viewMode]="'view'"
          [badgeView]="true"
        ></app-drop-down-input>
      </div>
    </div>

    <p-divider class="w-full" align="left">
      <b>{{ moduleFieldString + '.Residual.label' | translate: 'Residual' }}</b>
    </p-divider>
    <div class="flex flex-column w-12 md:w-6">
      <div class="col-12">
        <app-drop-down-input
          [label]="moduleFieldString + '.strategy.label' | translate: 'Response Strategy'"
          [placeholder]="moduleFieldString + '.strategy.placeholder' | translate: 'Select Item'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="
            formGroup?.controls?.residualRisk?.value <= riskMeth?.acceptableRisk ? strategyList : strategyFilteredList
          "
          [optionValue]="'value'"
          [control]="formGroup?.controls?.strategy"
          [viewMode]="residualFieldsViewMode"
          [badgeView]="true"
          [helpText]="
            formGroup?.controls?.residualRisk?.value > riskMeth?.acceptableRisk &&
            !formGroup?.controls?.strategy?.disabled
              ? 'Cannot Accept Risk Exceeding: ' + riskMeth?.acceptableRisk
              : ''
          "
        ></app-drop-down-input>
      </div>
      <div class="col-12">
        <app-target-code-selector
          [label]="moduleFieldString + '.controls.label' | translate: 'Controls'"
          [placeholder]="moduleFieldString + '.controls.placeholder' | translate: 'Search Controls'"
          [control]="formGroup?.controls?.controls"
          [viewMode]="residualFieldsViewMode"
          [multi]="true"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [extraFilters]="
            (formGroup?.controls?.entityId?.value?.code ?? formGroup?.controls?.entityId?.value ?? null)
              ? [
                  {
                    property: 'controlEntity',
                    operation: 'EQUAL',
                    value: formGroup?.controls?.entityId?.value?.code ?? formGroup?.controls?.entityId?.value ?? null,
                  },
                ]
              : []
          "
          [targetTypes]="['CONTROL']"
        >
        </app-target-code-selector>
      </div>
    </div>
    <div class="flex flex-column w-12 md:w-6">
      <div class="col-12">
        <app-drop-down-input
          [label]="moduleFieldString + '.residualImpact.label' | translate: 'Residual Risk Impact'"
          [placeholder]="moduleFieldString + '.residualImpact.placeholder' | translate: 'Select Residual Risk Impact'"
          [name]="'re-risk-imp'"
          [optionLabel]="'label'"
          [items]="impactList"
          [control]="formGroup?.controls?.residualImpactDto"
          [viewMode]="residualFieldsViewMode"
          [dataKey]="'code'"
          [showStatusBadge]="false"
        ></app-drop-down-input>
      </div>
      <div class="col-12">
        <app-drop-down-input
          [label]="moduleFieldString + '.residualLikelihood.label' | translate: 'Residual Likelihood'"
          [placeholder]="
            moduleFieldString + '.residualLikelihood.placeholder' | translate: 'Select Residual Likelihood'
          "
          [name]="'re-risk-imp'"
          [optionLabel]="'name'"
          [items]="likelihoodList"
          [control]="formGroup?.controls?.residualLikelihoodDto"
          [viewMode]="residualFieldsViewMode"
          [dataKey]="'code'"
          [showStatusBadge]="false"
        >
          <ng-template let-item #selectedItemTemplate>
            <span class="">
              {{
                item.label +
                  ' ( ' +
                  ('general.actions.from' | translate) +
                  ' ' +
                  item.occuresFrom +
                  ' ' +
                  ('general.actions.to' | translate) +
                  ' ' +
                  item.occuresTo +
                  (item.occurrenceDuration
                    ? ' ' + ('general.actions.times per' | translate) + ' ' + (item.occurrenceDuration | humanize)
                    : '') +
                  ' )'
              }}
            </span>
          </ng-template>
          <ng-template let-item #itemTemplate>
            <span class="">
              {{
                item.label +
                  ' ( ' +
                  ('general.actions.from' | translate) +
                  ' ' +
                  item.occuresFrom +
                  ' ' +
                  ('general.actions.to' | translate) +
                  ' ' +
                  item.occuresTo +
                  (item.occurrenceDuration
                    ? ' ' + ('general.actions.times per' | translate) + ' ' + (item.occurrenceDuration | humanize)
                    : '') +
                  ' )'
              }}
            </span>
          </ng-template>
        </app-drop-down-input>
      </div>
      <div class="col-12">
        <app-risk-meter-bar
          [label]="moduleFieldString + '.residualRisk.label' | translate: 'Residual Risk'"
          [riskMeth]="riskMeth"
          [value]="formGroup?.controls?.residualRisk?.value"
          [viewMode]="residualFieldsViewMode"
        ></app-risk-meter-bar>
      </div>
      <div class="col-12">
        <app-drop-down-input
          [label]="moduleFieldString + '.residualRiskAcceptance.label' | translate: 'Residual Risk Acceptance'"
          [placeholder]="moduleFieldString + '.residualRiskAcceptance.placeholder' | translate: 'Select Item'"
          [name]="'residualRiskAcceptanceList'"
          [optionLabel]="'label'"
          [items]="residualRiskAcceptanceList"
          [optionValue]="'value'"
          [control]="formGroup?.controls?.residualRiskAcceptance"
          [viewMode]="'view'"
          [badgeView]="true"
        ></app-drop-down-input>
      </div>
    </div>

    <p-divider class="w-full" align="left">
      <b>{{ moduleFieldString + '.riskNotes.label' | translate: 'Residual' }}</b>
    </p-divider>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'152px'"
        [name]="'notes-area'"
        [label]="moduleFieldString + '.riskNotes.label' | translate: 'Risk Notes'"
        [placeholder]="moduleFieldString + '.riskNotes.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.riskNotes"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
  </div>
  @if (!hideActions) {
    <div class="flex flex-row-reverse justify-content-between align-items-center">
      <div class="flex flex-row-reverse gap-2">
        @if (showSaveButton) {
          <app-button [action]="submitButtonAction"></app-button>
        }
        @if (showSaveButton) {
          <app-button [action]="submitSaveButtonAction"></app-button>
        }
        @if (showInPlaceButton) {
          <app-button [action]="submitInPlaceButtonAction"></app-button>
        }
        @if (fieldViewMode == 'create') {
          <app-button [action]="cancelButtonAction"></app-button>
        }
      </div>
      @if (fieldViewMode == 'create') {
        <app-switch-input
          [label]="'general.messages.create another record' | translate"
          [name]="'inPlaceCreate'"
          [labelWidthFit]="true"
          [control]="inPlaceCreateControl"
          [viewMode]="'create'"
          [styleClass]="'mb-0'"
        >
        </app-switch-input>
      }
    </div>
  }
</form>
